import React from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { graphql, PageProps } from "gatsby";
import QuickChat from "../../components/QuickChat";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../../components/Link";
import "./styles.scss";
import PageTitle from "../../components/PageTitle";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import {
  ImageInterface,
  LocalizedContextInterface,
  PortableText,
  MultiLineHeadline,
  Seo,
  SanityColorList,
  InternalLink
} from "../../types/SanityTypes";

type ContactUsQueryProps = {
  sanityContactUs: {
    _id: string;
    banner: {
      headline: MultiLineHeadline;
      primaryColor: SanityColorList;
      _rawShortIntro: PortableText;
      _rawHeroImage: ImageInterface;
    };
    faqLabel: string;
    faqCTA: string;
    faqPage: InternalLink;
    emailUsLabel: string;
    emailUsCTA: string;
    emailUsPage: InternalLink;
    mailUsLabel: string;
    _rawMailUsText: PortableText;
    callUsLabel: string;
    _rawCallUsText: PortableText;
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type ContactUsPageContext = LocalisedPageContext;

export const query = graphql`
  query ContactUsData($_id: String, $language: String, $market: String) {
    sanityContactUs(_id: { eq: $_id }) {
      _id
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        primaryColor {
          value
          title
        }
        _rawShortIntro(resolveReferences: { maxDepth: 8 })
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
      }
      faqLabel
      faqCTA
      faqPage {
        _id
        slug {
          current
        }
      }
      emailUsLabel
      emailUsCTA
      emailUsPage {
        _id
        slug {
          current
        }
      }
      mailUsLabel
      _rawMailUsText(resolveReferences: { maxDepth: 8 })
      callUsLabel
      _rawCallUsText(resolveReferences: { maxDepth: 8 })
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const ContactUs = (props: PageProps<ContactUsQueryProps, ContactUsPageContext>) => {
  const language = props.pageContext.language;
  const contact = props.data.sanityContactUs;
  const errorMessages = props.data.sanityLabels?.errorMessages;
  const { htmlLang, siteUrl } = useSiteMetadata(language);
  const pageTitle = contact.banner.headline.primaryText + " " + contact.banner.headline.secondaryText;
  const localizedPath = withPrefix(
    getLocalizedPath(props.data?.sanityContactUs?.slug?.current, props.data?.sanityContactUs?._id)
  );

  // This is to make sure banner works of internationalisation
  const lineOne =
    contact.banner.headline.primaryText.length <= 5
      ? contact.banner.headline.primaryText
      : contact.banner.headline.primaryText + " ";
  const lineTwo = contact.banner.headline.secondaryText === null ? " " : contact.banner.headline.secondaryText + " ";
  const lineThree = contact.banner.headline.tertiaryText === null ? " " : contact.banner.headline.tertiaryText + " ";

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"contact"}>
      <StructuredData
        type={"ContactPage"}
        data={contact.banner._rawHeroImage}
        customData={contact.seo.jsonld}
        language={htmlLang}
        slug={`/${contact.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={contact.seo.metaTitle}
        description={contact.seo.metaDescription}
        imageUrl={contact.banner._rawHeroImage.asset.url}
        imageAlt={contact.banner._rawHeroImage.alt}
      />
      <div className="page_contact-us">
        <Container fluid className="theme_blue" data-testid="contact-us">
          <Row className="header-type-1">
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={contact.banner._rawHeroImage}
                color={contact.banner.primaryColor.title}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="blocks" data-testid="faq-link" key="faq-link">
              <h2>{contact.faqLabel}</h2>
              {contact.faqPage && (
                <Link _id={contact.faqPage._id} to={contact.faqPage.slug.current} className="btn-primary">
                  {contact.faqCTA}
                </Link>
              )}
            </Col>
            <Col lg={6} className="blocks" data-testid="email-link" key="email-link">
              <h2>{contact.emailUsLabel}</h2>
              {contact.emailUsPage && (
                <Link
                  _id={contact.emailUsPage._id}
                  to={`${contact.slug.current}/${contact.emailUsPage.slug.current}`}
                  className="btn-primary"
                >
                  {contact.emailUsCTA}
                </Link>
              )}
            </Col>
          </Row>
          <Row>
            {" "}
            <Col lg={6} className="blocks">
              <h2>{contact.mailUsLabel}</h2>
              {contact._rawMailUsText && <BlockContent blocks={contact._rawMailUsText} className="intro-p" />}
            </Col>
            <Col lg={6} className="blocks">
              <h2>{contact.callUsLabel}</h2>
              <Row>
                <Col lg={5}>
                  {contact._rawCallUsText && <BlockContent blocks={contact._rawCallUsText} className="intro-p" />}
                </Col>
                <Col lg={7}>
                  <QuickChat errorMessages={errorMessages} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default ContactUs;
