import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as Bubbles } from "../../images/icons/bubbles.svg";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";
import { SanityErrorMessagesLabelsInterface } from "../../types/SanityTypes";

type QuickChatProps = SanityErrorMessagesLabelsInterface;

const QuickChat: FunctionComponent<QuickChatProps> = ({ errorMessages }) => {
  const {
    contactUsQCDeployment,
    contactUsQCLiveAgentInit,
    contactUsQCDeploymentId,
    contactUsQCOrgId,
    contactUsQCButtonId
  } = useSiteMetadata();

  const initializeAgent = () => {
    if(contactUsQCDeployment && contactUsQCLiveAgentInit) {
      liveagent.init(contactUsQCLiveAgentInit, contactUsQCDeploymentId, contactUsQCOrgId);
      if (!window._laq) {
        window._laq = [];
      }
      window._laq.push(function () {
        liveagent.showWhenOffline(contactUsQCButtonId, document.getElementById("liveagent_button_offline_"+contactUsQCButtonId));
        liveagent.showWhenOnline(contactUsQCButtonId, document.getElementById("liveagent_button_online_"+contactUsQCButtonId));
      });
    }
  };

  const initializeChat = () => {
    if(contactUsQCDeployment && contactUsQCLiveAgentInit) {
      liveagent.addButtonEventHandler(contactUsQCButtonId, function (e: never) {
        if (e == liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
          liveagent.startChat(contactUsQCButtonId);
        }
      });
      initializeAgent();
    }
  };

  return (
    <section data-testid="quick-chat" className="quick-chat">
      {contactUsQCDeployment && contactUsQCLiveAgentInit && (
        <>
          <Helmet>
            <script defer type="text/javascript" src={contactUsQCDeployment}></script>
          </Helmet>
          <div className="align">
            <div className="blocks">
              <div className="svg-border" onClick={initializeChat} id={"liveagent_button_online_"+contactUsQCButtonId}>
                <Bubbles className="svg" />
              </div>
              <div className="btn-primary" style={{ display: "none" }} id={"liveagent_button_offline_"+contactUsQCButtonId}>
                {errorMessages?.contactAgentError}
              </div>
            </div>
          </div>
        </>
      )}
      
    </section>
  );
};

export default QuickChat;
